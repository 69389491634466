import React from "react";
import { connect } from "react-redux";
import { auth } from "../../../firebase/firebase.utils";
import { useEffect, useState } from "react";
import { API_SERVER_URL } from "../../../util/config";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import AdminRequestlistsComponent from "../../../components/admin/admin-requestlist-component/admin-requestlist-component";

const REQUEST_LIST_URL = `${API_SERVER_URL}/requestlist/admin`;
const AdminRequestlistsPage = ({ currentUser, loading }) => {
  const [requestLists, setRequestLists] = useState([]);
  const [date, setDate] = useState(new Date());
  const [status, setStatus] = useState("");

  useEffect(() => {
    let mounted = true;
    const getRequestList = async () => {
      if (mounted) {
        setRequestLists([]);
        const data = await fetchRequestList(currentUser);
        setRequestLists(data);
      }
    };
    const fetchRequestList = async (user) => {
      if (!auth.currentUser) {
        return [];
      }
      let dateString = getDateString(date);
      let url = REQUEST_LIST_URL + "/date/" + dateString;
      if (status && status !== "None") {
        url = url + "/status/" + status;
      }
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.currentUser.idToken}`,
        },
      });
      if (res.ok) {
        const data = await res.json();
        return data;
      } else {
        console.log(await res.json());
        return [];
      }
    };
    getRequestList();
    return () => (mounted = false);
  }, [currentUser, date, status]);


  function getDateString(d) {
    let separator = "-";
    let date = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    return `${year}${separator}${
      month < 10 ? `0${month}` : `${month}`
    }${separator}${date}`;
  }

  function onDateChange(d) {
    setDate(d);
  }

  function onStatusChange(e) {
    setStatus(e.target.value);
  }

  return (
    <div className="container">
      <p className="is-size-1 has-text-primary has-text-centered">
        Request Lists
      </p>
      <div className="columns">
        <div className="column is-one-fifth field">
          <DatePicker
            selected={date}
            onChange={onDateChange}
            dateFormat="MMMM d, yyyy"
          />
        </div>
        <div className="column">
          <div className="select">
            <select onChange={onStatusChange}>
              <option value="None">None</option>
              <option value="open">open</option>
              <option value="confirm">confirm</option>
              <option value="close">close</option>
              <option value="cancel">cancel</option>
            </select>
        </div>
        </div>
      </div>

      <table className="table is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th className="is-align-items-center is-justify-content-center">
              No.
            </th>
            <th>Date</th>
            <th>Catagory</th>
            <th>
              <abbr title="No. of requests in the list">Num of Items</abbr>
            </th>
            <th>
              <abbr title="No. of responses from Vendor">Res</abbr>
            </th>
            <th>
              <abbr title="Current Status of the request">Status</abbr>
            </th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {requestLists.map((request, index) => (
            <AdminRequestlistsComponent
              key={index}
              index={index}
              id={request.id}
              createdAt={request.createdAt}
              category={request.category}
              requestCount={request.requestCount}
              responseCount={request.responseCount}
              status={request.status}
              user={currentUser}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = ({ user: { currentUser, loading } }) => ({
  currentUser,
  loading,
});

export default connect(mapStateToProps)(AdminRequestlistsPage);
