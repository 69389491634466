import React, { useState, useEffect } from "react";
import { auth } from "../../firebase/firebase.utils";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Route, Redirect } from 'react-router'

import { API_SERVER_URL } from "../../util/config";
import Directory from "../../components/directory/directory.component";
import "./homepage.styles.scss";
import LandingPageImage from "../../assets/images/LANDING-PAGE.jpg"

import { useHistory } from "react-router-dom";

const VENDOR_URL = `${API_SERVER_URL}/vendor`;

const HomePage = ({ currentUser, loading }) => {
  const [message, setMessage] = useState("");
  const [isVendor, setIsVendor] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const history = useHistory();

  useEffect(() => {
    let mounted = true;
    const checkIfVendor = async () => {
      const vendor = await fetchVendorDetails(currentUser);
      if (mounted) {
        if (!vendor) {
          setMessage("Please select the category to request the price");
        } else if (vendor && vendor.vendorStatus === "Active") {
          history.push("vendor/request-list");
          setMessage(
            "Go to 'Open Requests' to view the existing requests from your customers"
          );
        } else if (vendor && !vendor.vendorStatus === "Active")
          setMessage(
            "Cheers! You now registered for a vendor membership at shoot Price. \n\n Please wait for the verification, to proceed further"
          );
      }
    };
    if (currentUser) {
      setIsLoggedIn(true);
      setIsEmailVerified(auth.currentUser.emailVerified)
      checkIfVendor();
    }
    return () => (mounted = false);
  }, [currentUser]);

  const fetchVendorDetails = async (user) => {
    if (!auth.currentUser) {
      return {};
    }
    const res = await fetch(`${VENDOR_URL}/${user.currentUser.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.currentUser.idToken}`,
      },
    });

    if (res.status === 200) {
      const data = await res.json();
      setIsVendor(true);
      return data;
    } else {
      setIsVendor(false);
    }
    return;
  };

  return (
    <div className="homepage">
      {
        !isEmailVerified ? (<Redirect to="/verifyemail"/> ) : <p></p>
      }
      {isLoggedIn ? (<h1 className="title has-text-primary">Welcome to SHOOTPRICE!</h1>) : <img
                  src={LandingPageImage}
                  alt="title"
                  className="landing-image"
                />}
      <p className="m-3">{currentUser && message}</p>
      {isLoggedIn && isVendor ? (
        <p>
          {" "}
          Please navigate to{" "}
          <Link to="/vendor/request-list" className="option">
            Open Requests
          </Link>{" "}
        </p>
      ) : (<p></p>)}
      {isLoggedIn && !isVendor ? (
        <Directory />
      ) : (<p></p>)}
    </div>
  );
};

const mapStateToProps = ({ user: { currentUser, loading } }) => ({
  currentUser,
  loading,
});

export default connect(mapStateToProps)(HomePage);
