import React from "react";
import { withRouter, Link } from "react-router-dom";
// import { Frame } from "framer";

import "./menu-item.styles.scss";

const MenuItem = ({ title, imageUrl, history, match, linkUrl }) => {
  return (
    <Link to={linkUrl}>
      <div className="menu-item">
        <div className="card ">
          <div className="card-image">
            <figure className="image p-1">
              <center>
                <img
                  src={imageUrl}
                  alt="title"
                  style={{
                    // objectFit: "cover",
                    height: "325px",
                    width: "650px",
                  }}
                />
              </center>
            </figure>
          </div>
          <div className="card-content">
            <div className="media-content">
              <center>
                <p className="title is-4">{title}</p>
              </center>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
// className="menu-item column"
// onClick={() => {
//   history.push(`${match.url}${linkUrl}`);
// }}

/* <div
        className="background-image"
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      ></div>
      <div className="content">
      <img src={imageUrl} alt="categoryImage" className="catagoryImage" /> 
        <h1 className="title"> {title}</h1>
        <span className="subtitle">SHOP NOW</span>
      </div> */

export default withRouter(MenuItem);
