export const CATEGORIES = {
    "Computers" : "Computers and Accessories",
    "Mobiles": "Mobiles and Tablets",
    "Electricals": "Electricals and Electronics",
    "Stationary": "Books and Stationary"
}

export const CATEGORIESLIST  = [
    "Computers and Accessories",
    "Mobiles and Tablets",
    "Electricals and Electronics",
    "Books and Stationary"
]
    
//export default CATEGORIES
