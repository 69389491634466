import { Link } from "react-router-dom";

const VendorRequestItemComponent = ({ request }) => {
  const { id, category, createdAt, status, displayName } = request;
  //const dt = new Date(createdAt).toString().split(" ").slice(1, 4);
  const date = new Date(createdAt._seconds * 1000).toDateString();;
  //const reqDate = dt[1] + "-" + dt[0] + "-" + dt[2];
  return (
    <Link
      to={`/vendor/request-list/${id}`}
      className="card request-item-list column m-3"
      key={id}
    >
      <div className="card-header">
        <div className="card-header-title">User Name: {displayName} </div>
      </div>
      <div className="card-content p-4">
        <b>Order Id</b>: {id} <br />
        <b>Date</b>: {date} <br />
        <b>Category</b> : {category} <br />
      </div>
      <div className="card-content">
        <b>Status</b> : {status }
      </div>
    </Link>
  );
};

export default VendorRequestItemComponent;
