import React from "react";

import ForgotPassword from "../../components/forgot-password/forgot-password.component";

import "./forgot-password-page.styles.scss";

const ForgotPasswordPage = () => {
  return (
    <div className="forgot-password-page container px-4">
      <ForgotPassword />
    </div>
  );
};

export default ForgotPasswordPage;
