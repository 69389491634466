import { BiNoEntry } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";

import "./item.styles.scss";

const Item = ({ itemDetails, id, onDelete, onEdit }) => {
  return (
    <div className="item container">
      <p className="content is-clipped">{itemDetails}</p>
      <div className="icon-container pl-6">
        <BiNoEntry
          style={{ color: "red", cursor: "pointer" }}
          className="icon delete-icon"
          onClick={() => onDelete(id)}
        />
        &nbsp;
        <FiEdit
          style={{ color: "blue", cursor: "pointer" }}
          className="icon edit-icon"
          onClick={() => onEdit(id)}
        />
      </div>
    </div>
  );
};

export default Item;
