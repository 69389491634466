import React from "react";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { API_SERVER_URL } from "../../../util/config";
import AdminVendorComponent from "../../../components/admin/AdminVendorComponent/admin-vendor.component"


import "react-datepicker/dist/react-datepicker.css";

const VENDOR_LIST_URL = `${API_SERVER_URL}/vendor`;
const AdminVendorsPage = ({ currentUser, loading }) => {
  const [vendorList, setVendorList] = useState([]);

  useEffect(() => {
    let mounted = true;
    const getVendorsList = async () => {
      if (mounted) {
        setVendorList([]);
        const data = await fetchVendors(currentUser);
        console.log(data)
        setVendorList(data);
      }
    };
    const fetchVendors = async (user) => {
      if (!user || !user.currentUser) {
        return [];
      }
      const res = await fetch(VENDOR_LIST_URL, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.currentUser.idToken}`,
        },
      });
      if (res.ok) {
        const data = await res.json();
        return data;
      } else {
        return [];
      }
    };
    getVendorsList();
    return () => (mounted = false);
  }, [currentUser]);

  return (
    <div className="container">
        <p className="is-size-1 has-text-primary has-text-centered">Vendors</p>
        <br/>

        <table className="table is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>id</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Numbers</th>
            <th>Address</th>
            <th>Categories</th>
            <th>Vendor Status</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {vendorList.map((request, index) => (
            <AdminVendorComponent
              key={index}
              index={index}
              id={request.id}
              name={request.name}
              email={request.email}
              phoneNumbers={request.phone}
              address={request.address}
              categories={request.categories}
              vendorStatus={request.vendorStatus}
              user={currentUser}
            />
          ))}
        </tbody>
      </table>
      <br/>
    </div>
  );
};

const mapStateToProps = ({ user: { currentUser, loading } }) => ({
  currentUser,
  loading,
});

export default connect(mapStateToProps)(AdminVendorsPage);
