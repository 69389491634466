import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import { auth } from "../../firebase/firebase.utils";

const EmailVerifyPage = ({ currentUser, loading }) => {
  const [message, setMessage] = useState(
    "Your email id is not verified, please verify your email id to proceed further"
  );
  const [verifyEmailButtonClass, setVerifyEmailButtonClass] = useState("button is-warning");
  const [gotoHomePageButtonClass, setGotoHomePageButtonClass] = useState("button is-hidden");

  useEffect(() => {
    auth.onAuthStateChanged(async (userAuth) => {
        if(userAuth && userAuth.emailVerified)
        {
          window.location.href = window.location.origin
        }
      })
  }, [currentUser]);

  const sendVerificationEmail = async (user) => {
    if (auth.currentUser) {
      await auth.currentUser.sendEmailVerification();
      setMessage(
        "An email has been sent to your email id: " + auth.currentUser.email
      );
      setVerifyEmailButtonClass("is-hidden");
      setGotoHomePageButtonClass("button is-warning");
    }
  };

  const gotoHomePage = async() =>{
    window.location.href = window.location.origin
  }

  return (
    <div className="container">
      <p className="is-size-1">Verify Email</p>
      <p> {message} </p>
      <button className={verifyEmailButtonClass} onClick={sendVerificationEmail}>
        {" "}
        Send Verification Email{" "}
      </button>

      <button className={gotoHomePageButtonClass} onClick={gotoHomePage}>
        {" "}
        Go To Home Page{" "}
      </button>
    </div>
  );
};

const mapStateToProps = ({ user: { currentUser, loading } }) => ({
  currentUser,
  loading,
});

export default connect(mapStateToProps)(EmailVerifyPage);
