import { useState } from "react";
import { BiRupee } from "react-icons/bi";

import MyRequestListWithIdRowComponent from "../my-request-list-with-id-row/my-request-list-with-id-row.component";

const MyRequestListWithIdComponent = ({
  reqListId,
  user,
  requestList,
  responses,
  vendorId,
  vendorList
}) => {
  const [listTotal, setListTotal] = useState(0);

  return (
    <div className="card my-request column  m-3">
      <div className="card-header">
        <div className="card-header-icon">
          <BiRupee />/<BiRupee />
        </div>
        <div className="card-header-title">
          <center>{ vendorList[vendorId] && vendorList[vendorId].name }</center>
        </div>
      </div>
      <div className="my-request-list py-1 px-6 is-3">
        <div className=" table-container py-5">
          <table className="table is-narrow is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th className="is-align-items-center is-justify-content-center">
                  No.
                </th>
                <th>Item</th>
                <th>
                  <abbr title="Quatity">Qty</abbr>
                </th>
                <th>
                  <abbr title="Price Per One Item">Price</abbr>
                </th>
                <th>
                  <abbr title="Tax Percent">Tax%</abbr>
                </th>
                <th>
                  <abbr title="Total price of the Item">Total</abbr>
                </th>
              </tr>
            </thead>
            <tbody>
              {requestList &&
                requestList.map((request, index) => (
                  <MyRequestListWithIdRowComponent
                    key={index}
                    index={index}
                    request={request}
                    responses={responses}
                    vendorId={vendorId}
                    setListTotal={setListTotal}
                  />
                ))}
              <tr>
                <td
                  className="py-2 my-3"
                  colSpan="4"
                  style={{ textAlign: "right" }}
                >
                  <h4 className="pr-2 mr-2"> Total :</h4>
                </td>
                <td className="py-2 my-3" style={{ textAlign: "left" }}>
                  <h4 className="pr-2 mr-2"> {listTotal || 0}</h4>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MyRequestListWithIdComponent;
