import React from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";


const AdminPage = ({ user }) => {
const history = useHistory();
  useEffect(() => {
  }, [user]);

  return (
    <div className="container">
      <p className="is-size-1 has-text-primary has-text-centered">Welcome Admin</p>
      <br/>
      <br/>
      <button className="button is-primary" onClick={() => history.push('/admin/orders')}>Orders</button>
      <br/>
      <br/>
      <button className="button is-primary" onClick={() => history.push('/admin/vendors')}>Vendors</button>
    </div>

  );
};

const mapStateToProps = ({ user: { currentUser, loading } }) => ({
  currentUser,
  loading,
});

export default connect(mapStateToProps)(AdminPage);
