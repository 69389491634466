import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";

const MyRequestComponent = ({
  id,
  index,
  createdAt,
  category,
  requestCount,
  responseCount,
  status,
}) => {
  const dt = new Date(createdAt._seconds * 1000).toString().split(" ").slice(1, 4);
  const reqDate = dt[1] + "-" + dt[0] + "-" + dt[2];
  return (
    <tr>
      <td className="py-2">{index + 1}</td>
      <td className="py-2">{reqDate}</td>
      <td className="py-2">{category}</td>
      <td className="py-2">{requestCount}</td>
      <td className="py-2">{responseCount}</td>
      <td className="py-2">{status}</td>
      <td>
        <Link to={`/my-requests/${id}`} key={id}>
          <FaEye className="my-2" />
        </Link>
      </td>
    </tr>
  );
};

export default MyRequestComponent;
