import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { auth } from "../../firebase/firebase.utils";
import "./header.styles.scss";
// import {ReactComponent as Logo } from "../../assets/icons/price-tags.svg"
import logo from "../../assets/images/logo.png";

const Header = ({ currentUser }) => {
  return (
    <div className="header">
      <Link to="/" className="logo-container my-6 mx-3 mt-3">
        <img src={logo} alt="logo" className="logo" width="auto" />
      </Link>

      <div className="options">
      </div>
      {currentUser ? (
        currentUser && currentUser.currentUser.isVendor ? (
          <div className="options">
            <Link to="/vendor/request-list" className="option">
              Open Requests
            </Link>
            <div className="option" onClick={() => auth.signOut()}>
              Sign out
            </div>
          </div>
        ) : (
          <div className="options">
            <Link to="/my-requests" className="option">
              My Requests
            </Link>
            <div className="option" onClick={() => auth.signOut()}>
              Sign out
            </div>
          </div>
        )
      ) : (
        <div className="options">
          <Link to="/sign-in" className="option">
            Login
          </Link>
          <Link to="/sign-up" className="option">
            Sign up
          </Link>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(Header);
