import React from "react";
import { connect } from "react-redux";

import SignUp from "../../../components/sign-up/sign-up.component";

import "./vendor-sign-up-page.styles.scss";

const VendorSignUpPage = ({ currentUser, loading }) => {
  console.log("page", currentUser);
  return (
    <div className="container">
      <div className="vendor-message container mx-6 px-3">
        <h1 align="center">
          Come join us to help the customers get better prices
        </h1>
        <p>
          
        </p>
      </div>
      {!loading || currentUser ? (
        <div className="sign-up-page">
          <SignUp isVendor={true} user={currentUser} />
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = ({ user: { currentUser, loading } }) => ({
  currentUser,
  loading,
});

export default connect(mapStateToProps)(VendorSignUpPage);
