import React from "react";
import { connect } from "react-redux";

import SignIn from "../../components/sign-in/sign-in.component";

// import "./sign-in.styles.scss";

const SignInPage = ({ currentUser, loading }) => {
  return (
    <div className="sign-in-page container">
      {!loading || currentUser ? (
        <div className="sign-in-page">
          <SignIn isVendor={false} />
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = ({ user: { currentUser, loading } }) => ({
  currentUser,
  loading,
});

export default connect(mapStateToProps)(SignInPage);
