import { React, Component } from "react";
import { Link } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";

import FormInput from "../form-input/form-input.component";
import CustomButton from "../custom-button/custom-button.component";
import { auth } from "../../firebase/firebase.utils";
import "./sign-in.styles.scss";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      email: "",
      password: "",
      isVendor: this.props.isVendor,
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = this.state;
    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch (error) {
      this.setState({ message: error.message });
    }
  };

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { message, email, password } = this.state;

    const errorStyles = {
      maxWidth: "318px",
      color: "red",
      border: "2px solid red",
      backgroundColor: "rgba(225, 0, 0, 0.15 )",
      borderRadius: "5px",
    };

    const containerStyles = {
      maxWidth: "318px",
    };

    return (
      <div className="container" styles={containerStyles}>
        <div className="container sign-in">
          <div className="content my-3 py-1">
            <h2>I already have an account</h2>
            <span>Sign in with your email and password</span>
          </div>

          {message.length > 0 && (
            <div style={errorStyles} className="p-2 my-4">
              {message}
              <div className="icon-container pl-6">
                <AiOutlineCloseCircle
                  className="icon"
                  onClick={() => this.setState({ message: "" })}
                />
              </div>
            </div>
          )}

          <form onSubmit={this.handleSubmit}>
            <div className="my-3 py-2">
              <FormInput
                type="email"
                name="email"
                id="email"
                label="Email"
                value={email}
                handleChange={this.handleChange}
                required
              />
            </div>
            <div className="my-3 py-2">
              <FormInput
                type="password"
                name="password"
                id="password"
                label="Password"
                value={password}
                handleChange={this.handleChange}
              />
            </div>

            <div className="buttons my-4">
              <CustomButton type="submit">SIGN IN</CustomButton>
              {/* <CustomButton
              type="button"
              onClick={signInWithGoogle}
              isGoogleSignIn
            >
              Sign in with Google
            </CustomButton> */}
              <Link to="/forgot-password">
                <p className="forgotPassword">Forgot password</p>
              </Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default SignIn;
