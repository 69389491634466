import React from "react";
import { connect } from "react-redux";
import { auth } from "../../../firebase/firebase.utils";
import { useEffect, useState } from "react";
import { API_SERVER_URL } from "../../../util/config";

import VendorRequestList from "../../../components/vendor-request-list/vendor-request-list.component";
import "./vendor-request-list-page.styles.scss";

const REQUEST_LIST_URL = `${API_SERVER_URL}/requestlist`;

const VendorRequestListPage = ({ user }) => {

  const [userRequestList, setUserRequestList] = useState([]);
  useEffect(() => {
    let mounted = true;
    const getRequestList = async () => {
      const allUserRequestLists = await fetchRequestList(user);
      if(mounted)
      {
        setUserRequestList(allUserRequestLists);
      }
    };
    getRequestList();
    return () => mounted = false;
  }, [user]);

  const fetchRequestList = async (user) => {
    if (!auth.currentUser) {
      return [];
    }
    const res = await fetch(REQUEST_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.currentUser.idToken}`,
      },
    });

    if (res.ok) {
      const data = await res.json();
      console.log(data)
      return data;
    } else {
      return [];
    }
  };

  return (
    <div className="vendor-request-list-page px-6">
      <VendorRequestList requestList={userRequestList} />
    </div>
  );
};

const mapStateToProps = ({ user: { currentUser, loading } }) => ({
  currentUser,
  loading,
});

export default connect(mapStateToProps)(VendorRequestListPage);
