import { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";

import FormInput from "../form-input/form-input.component";
import CustomButton from "../custom-button/custom-button.component";
import { CATEGORIESLIST } from "../../categories.data";

import { auth } from "./../../firebase/firebase.utils";
import { API_SERVER_URL } from "../../util/config";

import "./sign-up.styles.scss";

const USER_URL = `${API_SERVER_URL}/user`;

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: CATEGORIESLIST,
      checkedState: new Array(5).fill(false),
      catagories: [],
      user: props.user,
      displayName: "",
      email: "",
      password: "",
      confirmPassword: "",
      isVendor: props.isVendor,
      phone: "",
      altPhone: "",
      address: "",
      errorMessage: "",
      successMessage: "",
      redirect: false,
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      displayName,
      email,
      password,
      confirmPassword,
      isVendor,
    } = this.state;
    if (confirmPassword !== password) {
      this.setState({ errorMessage: "Passwords don't match" });
      return;
    }
    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      );

      let token = await user.getIdToken().then((idToken) => idToken);

      const userObject = {
        displayName: displayName,
        isVendor: isVendor,
      };

      const reqOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(userObject),
      };

      let res = await fetch(USER_URL, reqOptions);
      if (res.status === 200) {
        window.location.href = window.location.origin + "/";
      } else {
        alert(`Error code : ${res.status}`);
      }
    } catch (error) {
      console.log(error)
      this.setState({ errorMessage: error.message });
    }
  };

  handleVendorSubmit = async (event) => {
    event.preventDefault();
    window.scrollTo(0, 0);
    const VENDOR_URL = `${API_SERVER_URL}/vendor`;
    const {
      displayName,
      email,
      address,
      phone,
      altPhone,
      catagories,
      password,
      confirmPassword,
      isVendor,
    } = this.state;

    const cleanPhone = phone.replace(/[^0-9]/g, "");
    const cleanAltPhone = altPhone.replace(/[^0-9]/g, "");

    if (!address || address.length < 20) {
      this.setState({ errorMessage: "Enter a valid address" });
      return;
    }

    if (
      cleanPhone.length < 10 ||
      cleanPhone.length > 10 ||
      cleanAltPhone.length < 10 ||
      cleanAltPhone.length > 10
    ) {
      this.setState({ errorMessage: "Enter a valid phone number" });
      return;
    }

    if (catagories.length === 0) {
      this.setState({ errorMessage: "Please select atleast one catagory" });
      return;
    }

    if (confirmPassword !== password) {
      this.setState({ errorMessage: "Passwords don't match" });
      return;
    }

    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      let token = await user.getIdToken().then((idToken) => idToken);

      const userObject = {
        displayName: displayName,
        isVendor: isVendor,
      };

      const reqOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(userObject),
      };

      await fetch(USER_URL, reqOptions);

      const vendorObject = {
        name: displayName,
        phone: [phone, altPhone],
        address: address,
        categories: catagories,
      };

      const reqOptions2 = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(vendorObject),
      };

      await fetch(VENDOR_URL, reqOptions2);
      window.location.href = window.location.origin;
    } catch (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleAddress = (event) => {
    this.setState({ address: event.target.value });
  };

  handleCatagories = (position) => {
    const { checkedState, options } = this.state;

    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    const updatedCatagories = [];
    for (let i = 0; i < options.length; i++) {
      if (updatedCheckedState[i] === true) {
        updatedCatagories.push(options[i]);
      }
    }

    this.setState(
      { checkedState: updatedCheckedState },
      this.setState({ catagories: updatedCatagories })
    );
  };

  render() {
    const {
      options,
      displayName,
      phone,
      altPhone,
      address,
      email,
      password,
      isVendor,
      confirmPassword,
      checkedState,
      errorMessage,
      successMessage,
      redirect,
    } = this.state;

    const errorStyles = {
      maxWidth: "318px",
      color: "red",
      border: "2px solid red",
      backgroundColor: "rgba(225, 0, 0, 0.15 )",
      borderRadius: "5px",
    };

    const successStyles = {
      maxWidth: "318px",
      color: "green",
      border: "2px solid green",
      backgroundColor: "rgba(0, 225, 0, 0.15 )",
      borderRadius: "5px",
    };

    const containerStyles = {
      maxWidth: "318px",
    };

    if (redirect) {
      return <Redirect to="/" />;
    }

    return isVendor ? (
      <div className="sign-up" style={containerStyles}>
        <div className="my-3 py-1">
          <span>Sign up with email and password</span>
        </div>

        {errorMessage.length > 0 && (
          <div style={errorStyles} className="p-2 my-4">
            {errorMessage}
            <div className="icon-container pl-6">
              <AiOutlineCloseCircle
                className="icon"
                onClick={() => this.setState({ errorMessage: "" })}
              />
            </div>
          </div>
        )}

        {successMessage.length > 0 && (
          <div style={successStyles} className="p-2 my-4">
            {successMessage}
            <div className="icon-container pl-6">
              {
                <AiOutlineCloseCircle
                  className="icon"
                  onClick={() => this.setState({ successMessage: "" })}
                />
              }
            </div>
          </div>
        )}

        <form className="sign-up-form" onSubmit={this.handleVendorSubmit}>
          <div className="my-3 py-2">
            <FormInput
              type="text"
              name="displayName"
              value={displayName}
              onChange={this.handleChange}
              label="Shop Name"
              required
            />
          </div>
          <div className="my-3 py-2">
            <FormInput
              type="email"
              name="email"
              value={email}
              onChange={this.handleChange}
              label="Email"
              required
            />
          </div>
          <div className="my-3 py-2">
            <label>Address</label>
            <textarea
              className="textarea is-primary"
              name="address"
              value={address}
              onChange={this.handleAddress}
              placeholder="Hno.1563/B, White Field, Bangalore"
              required
            ></textarea>
          </div>
          <div className="my-3 py-2">
            <FormInput
              type="tel"
              name="phone"
              value={phone}
              onChange={this.handleChange}
              label="Phone"
              // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
              required
            />
          </div>
          <div className="my-3 py-2">
            <FormInput
              type="tel"
              name="altPhone"
              value={altPhone}
              onChange={this.handleChange}
              label="Alt Phone"
              // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
            />
          </div>
          <div className="field">
            <div>
              <label className="label" style={{ fontWeight: "400" }}>
                Catagories
              </label>
              {options.map((catagory, index) => (
                <label className="checkbox" key={index}>
                  <input
                    key={index}
                    type="checkbox"
                    name={catagory}
                    value={catagory}
                    checked={checkedState[index]}
                    onChange={() => this.handleCatagories(index)}
                  />
                  &nbsp; {catagory} &nbsp;
                </label>
              ))}
            </div>
          </div>

          <div className="my-3 py-2">
            <FormInput
              type="password"
              name="password"
              value={password}
              onChange={this.handleChange}
              label="Password"
              required
            />
          </div>
          <div className="my-3 py-2">
            <FormInput
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={this.handleChange}
              label="Confirm Password"
              required
            />
          </div>
          <div className="my-4">
            <CustomButton type="submit">Sign up as Vendor</CustomButton>
          </div>
          <div className="my-4">
            <Link to="/sign-up">
              <CustomButton isAddItem>Sign up as User</CustomButton>
            </Link>
          </div>
        </form>
      </div>
    ) : (
      <div className="sign-up">
        <div className="my-3 py-1">
          <span>Sign up with email and password</span>
        </div>

        {errorMessage.length > 0 && (
          <div style={errorStyles} className="p-2 my-4">
            {errorMessage}
            <div className="icon-container pl-6">
              <AiOutlineCloseCircle
                className="icon"
                onClick={() => this.setState({ errorMessage: "" })}
              />
            </div>
          </div>
        )}

        {successMessage.length > 0 && (
          <div style={successStyles} className="p-2 my-4">
            {successMessage}
            <div className="icon-container pl-6">
              <AiOutlineCloseCircle
                className="icon"
                onClick={() => this.setState({ successMessage: "" })}
              />
            </div>
          </div>
        )}

        <form className="sign-up-form" onSubmit={this.handleSubmit}>
          <div className="my-3 py-2">
            <FormInput
              type="text"
              name="displayName"
              value={displayName}
              onChange={this.handleChange}
              label="Name"
              required
            />
          </div>
          <div className="my-3 py-2">
            <FormInput
              type="email"
              name="email"
              value={email}
              onChange={this.handleChange}
              label="Email"
              required
            />
          </div>
          <div className="my-3 py-2">
            <FormInput
              type="password"
              name="password"
              value={password}
              onChange={this.handleChange}
              label="Password"
              required
            />
          </div>
          <div className="my-3 py-2">
            <FormInput
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={this.handleChange}
              label="Confirm Password"
              required
            />
          </div>
          <div className="my-4">
            <CustomButton type="submit">Sign up as User</CustomButton>
          </div>
          <div className="my-4">
            <Link to="/vendor/sign-up">
              <CustomButton isAddItem>Sign up as Vendor</CustomButton>
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

export default SignUp;
