import { useState } from "react";
import { useParams } from "react-router-dom";

import RequestList from "../../components/request-list/request-list.component";
import "./request-item-list-page.styles.scss";

const RequestItemListPage = ({ user }) => {
  const { category } = useParams();
  const [requestItemList] = useState([]);
  return (
    <div className="request-item-list-page container">
      <center>
        <h1>List the items to request the price</h1>
      </center>
      <RequestList
        requestItemList={requestItemList}
        user={user}
        categoryKey={category}
      />
    </div>
  );
};

export default RequestItemListPage;
