import { useState, useEffect } from "react";
const MyRequestListWithIdRowComponent = ({
  index,
  request = {},
  responses = {},
  requestList = [],
  vendorId = "",
  vendorList = {},
  requestId = "",
  isBestPrice = false,
  setListTotal,
  token,
}) => {
  const { requestString, quantity } = request;
  const [requestItem, setRequestItem] = useState({});
  const [bestPriceVendor, setBestPriceVendor] = useState("");

  useEffect(() => {
    if (isBestPrice) {
      const bestPriceArray = [];

      for (let resp in responses) {
        const obj = responses[resp];
        obj.requestString = requestList.filter(
          (ele) => ele.id === resp
        )[0].requestString;
        bestPriceArray.push(obj);
      }

      const requestItem = bestPriceArray.filter(
        (ele) => ele.requestId === requestId
      );
      const bestPriceVendorId = requestItem[0].vendorId;

      const bestPriceTotal = bestPriceArray.reduce((s, ele) => {
        return (
          s +
          (ele.priceForOneItem +
            ele.priceForOneItem *
              (ele.taxPercent / 100 || 18 / 100).toFixed(2)) *
            ele.quantity
        );
      }, 0);
      // const vendor = vendorList.filter(
      //   (vendor) => vendor.id === bestPriceVendorId
      // );
      var vendorName = "Unknown Shop"
      if(vendorList[bestPriceVendorId] && vendorList[bestPriceVendorId].name) 
      {
        vendorName = vendorList[bestPriceVendorId].name
      }

      setListTotal(bestPriceTotal);
      setRequestItem(requestItem[0]);
      setBestPriceVendor(vendorName)
      //setBestPriceVendor(vendor[0] && vendor[0].name);
    }
  }, [
    isBestPrice,
    requestId,
    responses,
    setListTotal,
    requestList,
    vendorId,
    vendorList,
  ]);

  useEffect(() => {
    if (!isBestPrice) {
      const itemList = responses && responses[vendorId];
      const requestItem = itemList.filter(
        (ele) => ele.requestId === request.id
      );
      const total = itemList.reduce((s, ele) => {
        return (
          s +
          (ele.priceForOneItem +
            ele.priceForOneItem *
              (ele.taxPercent / 100 || 18 / 100).toFixed(2)) *
            ele.quantity
        );
      }, 0);
      setRequestItem(requestItem[0]);
      setListTotal(total.toFixed(2));
    }
  }, [vendorId, request.id, responses, setListTotal, isBestPrice]);

  return isBestPrice ? (
    <tr key={index}>
      <td className="py-2">{index + 1}</td>
      <td className="py-2">{requestItem.requestString}</td>
      <td className="py-2">{requestItem.quantity}</td>
      <td className="py-2">{requestItem.priceForOneItem}</td>
      <td className="py-2">{requestItem.taxPercent || 18}</td>
      <td className="py-2">
        {(
          (requestItem.priceForOneItem + 
            (requestItem.priceForOneItem * (requestItem.taxPercent / 100 || 18 / 100)))
            * requestItem.quantity).toFixed(2)}
      </td>
      <td className="py-2">{bestPriceVendor}</td>
    </tr>
  ) : (
    requestItem && (
      <tr key={index}>
        <td className="py-2">{index + 1}</td>
        <td className="py-2">{requestString}</td>
        <td className="py-2">{quantity}</td>
        <td className="py-2">{requestItem.priceForOneItem}</td>
        <td className="py-2">{requestItem.taxPercent || 18}</td>
        <td className="py-2">
        {(
          (requestItem.priceForOneItem + 
            (requestItem.priceForOneItem * (requestItem.taxPercent / 100 || 18 / 100)))
            * requestItem.quantity).toFixed(2)}
        </td>
      </tr>
    )
  );
};

export default MyRequestListWithIdRowComponent;
