import { React, Component } from "react";
import Dropdown from "react-dropdown";
import { withRouter } from "react-router-dom";
import {
  REQUESTLISTSTATUSMAP,
  REQUESTLISTSTATUS,
} from "../../../requistlist-status.data";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";

import { API_SERVER_URL } from "../../../util/config";
const REQUEST_LIST_URL = `${API_SERVER_URL}/requestlist`;

class AdminRequestlistsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      createdAt: Date(this.props.createdAt._seconds),
      category: this.props.category,
      requestCount: this.props.requestCount,
      responseCount: this.props.responseCount,
      status: this.props.status,
      color: REQUESTLISTSTATUSMAP[props.status],
      user: this.props.user
    };
    this.state.createdAt = new Date(
      this.props.createdAt._seconds * 1000
    ).toDateString();
    this.onStatusChange = this.onStatusChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  async onStatusChange(event) {
    let color = REQUESTLISTSTATUSMAP[event.value];
    this.setState({ status: event.value });
    this.setState({ color: color });
    const url =  REQUEST_LIST_URL+ "/" + this.state.id;
    const patchObject = {
      status: event.value
    };
    const res = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.user.currentUser.idToken}`,
      },
      body: JSON.stringify(patchObject),
    });
    if (res.ok) {
      console.log("status updated to :" + event.value)
    }
    else{
      console.log(await res.json())
    }
  }

  onClick(event) {
    this.props.history.push("/my-Requests/" + this.state.id);
  }

  render() {
    return (
      <tr>
        <td className={this.state.color}>{this.state.id}</td>
        <td className={this.state.color}>{this.state.createdAt}</td>
        <td className={this.state.color}>{this.state.category}</td>
        <td className={this.state.color}>{this.state.requestCount}</td>
        <td className={this.state.color}>{this.state.responseCount}</td>
        <td className={this.state.color}>
          <Dropdown
            options={REQUESTLISTSTATUS}
            value={this.state.status}
            onChange={this.onStatusChange}
            placeholder="Select an option"
          />
        </td>
        <td className={this.state.color}>
          {" "}
          <Link to={`/my-requests/${this.state.id}`} key={this.state.id}>
            <FaEye className="my-2" />
          </Link>
        </td>
      </tr>
    );
  }
}

export default withRouter(AdminRequestlistsComponent);
