import { React, Component } from "react";

import MenuItem from "../menu-item/menu-item.component";
import ComputerImage from "../../assets/images/COMPUTE-LAPTOP-CCTV-ACCEROIRES.jpg"
import MobileImage from "../../assets/images/mobiles-tablets-home appliances.jpg"
import ElectricalImage from "../../assets/images/ELECTRICAL-HARDWARE-PLUMBING.jpg"
import BooksImage from "../../assets/images/BOOKS-stationary.jpg"
// import ComputersSVG from "../../assets/svg/computers.svg";
// import MobilesSVG from "../../assets/svg/mobiles.svg";
// import ElectronicsSVG from "../../assets/svg/electronics.svg";
// import TabletsSVG from "../../assets/svg/tablets.svg";
// import GroceriesSVG from "../../assets/svg/groceries.svg";
import "./directory.styles.scss";

class Directory extends Component {
  constructor() {
    super();
    this.state = {
      categories: [
        {
          title: "Computers and Accessories",
          // imageUrl: "../../../../src/assets/images/COMPUTE-LAPTOP-CCTV-ACCEROIRES.jpg",
          // imageUrl: ComputersSVG,
          imageUrl: ComputerImage,
          id: 1,
          linkUrl: "request-item-list/Computers",
        },
        {
          title: "Mobiles & Tablets",
          // imageUrl: "https://picsum.photos/id/373/480/480",
          // imageUrl: MobilesSVG,
          imageUrl: MobileImage,
          id: 2,
          linkUrl: "request-item-list/Mobiles",
        },
        {
          title: "Electricals and Electronics",
          // imageUrl: "https://picsum.photos/id/529/480/480",
          // imageUrl: ElectronicsSVG,
          imageUrl: ElectricalImage,
          id: 3,
          linkUrl: "request-item-list/Electricals",
        },
        {
          title: "Books and Stationary",
          // imageUrl: "https://picsum.photos/id/292/480/480",
          // imageUrl: GroceriesSVG,
          imageUrl: BooksImage,
          id: 5,
          linkUrl: "request-item-list/Stationary",
        },
      ],
    };
  }
  render() {
    // is-flex is-flex-wrap-wrap is-flex-direction-row is-justify-content-center
    return (
      <div className="directory-menu">
        {this.state.categories.map(({ id, ...otherCategoryProps }) => (
          <MenuItem key={id} {...otherCategoryProps} />
        ))}
      </div>
    );
  }
}

export default Directory;
