import { useState, useEffect } from "react";
import { BiNoEntry } from "react-icons/bi";
import FormInput from "../form-input/form-input.component";

const VendorRequestItemPriceComponent = ({
  id,
  index,
  itemName,
  quantity,
  taxPercent,
  handlePrice,
  handleTax,
  handleDelete,
}) => {
  const [tempTotalItemPrice, setTempTotalItemPrice] = useState(0);
  const [tempPricePerOne, setTempPricePerOne] = useState(0);
  const [tempTax, setTempTax] = useState(taxPercent);

  const handlePriceChange = (e, id, quantity, tax) => {
    var enteredPrice = parseInt(e.target.value)
    if(enteredPrice < 0)
    {
      setTempPricePerOne(0)
      enteredPrice = 0
    }
    setTempTotalItemPrice(((enteredPrice + enteredPrice * (tax / 100)) * quantity).toFixed(2));
    setTempPricePerOne(enteredPrice);
    handlePrice(e, id, quantity, tax);
  };

  const handleTaxChange = (e, id, quantity, price) => {
    setTempTotalItemPrice(
      price + price * (parseInt(e.target.value) / 100) * quantity
    );
    setTempTax(parseInt(e.target.value));
    handleTax(e, id, quantity, parseInt(price));
  };

  useEffect(() => {
    setTempTotalItemPrice(tempTotalItemPrice);
  }, [tempTotalItemPrice]);

  return (
    <tr>
      <td>
        <p>{index + 1}</p>
      </td>
      <td>{itemName}</td>
      <td>{quantity}</td>
      <td className="py-0">
        <FormInput
          type="number"
          name="priceForOneItem"
          value = {tempPricePerOne}
          onChange={(e) => handlePriceChange(e, id, quantity, tempTax)}
        />
      </td>
      <td>
        <FormInput
          type="number"
          name="taxPercent"
          value = {tempTax}
          onChange={(e) => handleTaxChange(e, id, quantity, tempPricePerOne)}
        />
      </td>
      <td>{tempTotalItemPrice || 0}</td>
      <td>
        <div className="mt-3">
          <BiNoEntry
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => handleDelete(id)}
          />
        </div>
      </td>
    </tr>
  );
};

export default VendorRequestItemPriceComponent;
