import { auth } from "../../firebase/firebase.utils";

import FormInput from "../form-input/form-input.component";
import CustomButton from "../custom-button/custom-button.component";

import React, { Component } from "react";

export class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      message: "",
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { email } = this.state;
    // console.log(email);
    auth.sendPasswordResetEmail(email).then(() => {
      this.setState({
        message:
          "We have sent you a password reset mail. Please check your spam",
      });
    });
  };

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { email, message } = this.state;
    return (
      <div className="container px-6 m-6">
        <center>
          <h2>
            Please enter your registered email ID and we'll send you a mail to
            reset your password
          </h2>
        </center>
        <form onSubmit={this.handleSubmit} className="px-6 m-6">
          <FormInput
            type="email"
            name="email"
            id="email"
            label="Email"
            value={email}
            handleChange={this.handleChange}
            required
          />
          <CustomButton type="submit">SUBMIT</CustomButton>
        </form>
        {message.length > 0 && (
          <center>
            <p> {message} </p>
          </center>
        )}
      </div>
    );
  }
}

export default ForgotPassword;
