import React from "react";
import { connect } from "react-redux";

import SignIn from "../../../components/sign-in/sign-in.component";

import "./vendor-sign-in-page.styles.scss";

const VendorSignInPage = ({ currentUser, loading }) => {
  return (
    <div className="container">
      <div className="vendor-message container mx-6 px-3">
        <h1 align="center">
          Come join us to help the customers get a better price
        </h1>
        <p>
          
        </p>
      </div>
      <div className="sign-in-page container">
        {!loading || currentUser ? (
          <div className="sign-in-page">
            <SignIn isVendor={true} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = ({ user: { currentUser, loading } }) => ({
  currentUser,
  loading,
});

export default connect(mapStateToProps)(VendorSignInPage);
