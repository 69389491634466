import React from "react";
import { connect } from "react-redux";

import SignUp from "../../components/sign-up/sign-up.component";

const SignUpPage = ({ currentUser, loading }) => {
  return (
    <div className="sign-up-page">
      {!loading || currentUser ? (
        <div className="sign-up-page">
          <SignUp isVendor={false} user={currentUser} />
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = ({ user: { currentUser, loading } }) => ({
  currentUser,
  loading,
});

export default connect(mapStateToProps)(SignUpPage);
