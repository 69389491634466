import { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

//import "./App.scss";
//import "css/App.css"
import "bulma/css/bulma.min.css";
import HomePage from "./pages/homepage/homepage.component";
import StorePage from "./pages/storepage/storepage.component";
import SignInPage from "./pages/sign-in-page/sign-in-page.component";
import SignUpPage from "./pages/sign-up-page/sign-up-page.component";
import RequestItemListPage from "./pages/request-item-list-page/request-item-list-page.component";
import Header from "./components/header/header.component";
import MyRequestListPage from "./pages/my-request-list-page/my-request-list-page.component";
import MyRequestListWithIdPage from "./pages/my-request-list-with-id-page/my-request-list-with-id-page.component";
import ForgotPasswordPage from "./pages/forgot-password-page/forgot-password-page.component";

import VendorSignInPage from "./pages/vendor/sign-in-page/vendor-sign-in-page.component";
import VendorSignUpPage from "./pages/vendor/sign-up-page/vendor-sign-up-page.component";
import VendorRequestListPage from "./pages/vendor/request-list-page/vendor-request-list-page.component";
import VendorRequestListWithIdPage from "./pages/vendor/request-list-with-id-page/vendor-request-list-with-id-page.component";
import AdminPage from "./pages/admin/admin-page/admin-page.component";
import AdminRequestlistsPage from "./pages/admin/admin-requestlists-page/admin-requestlists-page.component";
import AdminVendorsPage from "./pages/admin/admin-vendors-page/admin-vendors-page.component";

import { auth } from "./firebase/firebase.utils";
import { setCurrentUser, setLoading } from "./redux/user/users.actions";
import EmailVerifyPage from "./pages/email-verify-page/email-verify-page.component";
import { API_SERVER_URL } from "./util/config";

const USER_URL = `${API_SERVER_URL}/user`;

class App extends Component {
  unsubscribeFromAuth = null;

  componentDidMount() {
    const { setCurrentUser, setLoading } = this.props;
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      setLoading(true);
      if (userAuth) {
        let token = await userAuth.getIdToken().then((idToken) => idToken);
        setLoading(false);
        const reqOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        let url = USER_URL + "/" + userAuth.uid;
        let res = await fetch(url, reqOptions);
        if (res.status === 200) {
          const data = await res.json();
          setCurrentUser({
            currentUser: {
              id: data.id,
              idToken: token,
              isVendor: data.isVendor,
            },
          });
        } }
        else {
          var path = window.location.pathname;
          var unauthRoutes = ["/sign-in", "/sign-up", "/verifyemail", "/"];
          if (!unauthRoutes.includes(path)) {
            window.location.href = window.location.origin + "/sign-in";
          }
          setLoading(false);
          setCurrentUser(userAuth);
        }
      }
    );
  }

  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }

  render() {
    return (
      <div>
        <Header />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/store" component={StorePage} />
          <Route
            exact
            path="/sign-in"
            render={() =>
              this.props.currentUser ? <Redirect to="/" /> : <SignInPage />
            }
          />
          <Route
            exact
            path="/sign-up"
            render={() =>
              this.props.currentUser ? <Redirect to="/" /> : <SignUpPage />
            }
          />

          <Route exact path="/admin/vendors">
            <AdminVendorsPage user={this.props.currentUser} />
          </Route>

          <Route exact path="/verifyemail">
            <EmailVerifyPage user={this.props.currentUser} />
          </Route>

          <Route
            exact
            path="/request-item-list"
            render={(props) =>
              !this.props.currentUser ? (
                <Redirect to="/sign-in" />
              ) : (
                <RequestItemListPage user={this.props.currentUser} />
              )
            }
          />
          <Route exact path="/request-item-list/:category">
            {!this.props.currentUser ? (
              <Redirect to="/sign-in" />
            ) : (
              <RequestItemListPage user={this.props.currentUser} />
            )}
          </Route>
          <Route exact path="/my-requests" component={MyRequestListPage} />
          <Route exact path="/my-requests/:id">
            <MyRequestListWithIdPage user={this.props.currentUser} />
          </Route>
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route
            exact
            path="/vendor/sign-in"
            render={() =>
              this.props.currentUser && !this.props.currentUser.isVendor ? (
                <Redirect to="/" />
              ) : (
                <VendorSignInPage />
              )
            }
          />
          <Route
            exact
            path="/vendor/sign-up"
            render={() =>
              this.props.currentUser && this.props.currentUser.isVendor ? (
                <Redirect to="/vendor/request-list" />
              ) : (
                <VendorSignUpPage />
              )
            }
          />

          <Route
            exact
            path="/vendor/request-list"
            render={() => (
              <VendorRequestListPage user={this.props.currentUser} />
            )}
          />
          <Route exact path="/vendor/request-list/:id">
            <VendorRequestListWithIdPage user={this.props.currentUser} />
          </Route>
          <Route exact path="/admin">
            <AdminPage user={this.props.currentUser} />
          </Route>
          <Route exact path="/admin/orders">
            <AdminRequestlistsPage user={this.props.currentUser} />
          </Route>
          <Route exact path="/admin/vendors">
            <AdminVendorsPage user={this.props.currentUser} />
          </Route>
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  setLoading: (boolean) => dispatch(setLoading(boolean)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
