import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { auth } from "../../../firebase/firebase.utils";
import { useEffect, useState } from "react";
import { API_SERVER_URL } from "../../../util/config";

import VendorRequestListWithIdComponent from "../../../components/vendor-request-list-with-id/vendor-request-list-with-id.component";
import "./vendor-request-list-with-id-page.styles.scss";

const REQUEST_LIST_URL = `${API_SERVER_URL}/requestlist`;

const VendorRequestListWithIdPage = ({ user }) => {
  const { id } = useParams();
  const [requestsWithListId, setRequestsWithListId] = useState([]);
  useEffect(() => {
    const getRequestList = async () => {
      const requestsWithId = await fetchRequestList(user, id);
      setRequestsWithListId(requestsWithId);
    };
    getRequestList();
  }, [user, id]);

  const fetchRequestList = async (user, id) => {
    if (!auth.currentUser) {
      //alert("Please sign-in as a vendor before you start shooting your price");
      return [];
    }

    const res = await fetch(`${REQUEST_LIST_URL}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.currentUser.idToken}`,
      },
    });
    const data = await res.json();
    console.log(data)
    return data;
  };

  return (
    <div className="vendor-request-list-with-id-page">
      <VendorRequestListWithIdComponent
        requests={requestsWithListId.requests}
        reqId={id}
        user={user}
      />
    </div>
  );
};

const mapStateToProps = ({ user: { currentUser, loading } }) => ({
  currentUser,
  loading,
});

export default connect(mapStateToProps)(VendorRequestListWithIdPage);
