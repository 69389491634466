import { useEffect, useState, useCallback } from "react";
import { useParams, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { auth } from "../../firebase/firebase.utils";
import { BiRupee } from "react-icons/bi";

import { API_SERVER_URL } from "../../util/config";

import CustomButton from "../../components/custom-button/custom-button.component";
import MyRequestListWithIdComponent from "../../components/my-request-list-with-id/my-request-list-with-id.component";
import MyRequestListWithIdRowComponent from "../../components/my-request-list-with-id-row/my-request-list-with-id-row.component";

const REQUEST_LIST_WITH_RESPONSES_URL = `${API_SERVER_URL}/responses/requestlist`;
const REQUEST_LIST_URL = `${API_SERVER_URL}/requestlist`;

const MyRequestListWithIdPage = ({ user }) => {
  const { id } = useParams();
  const [responses, setResponses] = useState([]);
  const [requests, setRequests] = useState([]);
  const [bestPriceItemList, setBestPriceItemList] = useState([]);
  const [bestPriceListTotal, setBestPriceListTotal] = useState(0);
  const [vendorList, setVendorList] = useState([]);
  const [showModal, setShowModal] = useState("modal");
  const [confirmButtonString, setConfirmButtonString] = useState("Confirm");
  const [status, setStatus] = useState("");
  const [tempAddress, setTempAddress] = useState("");
  const [tempPhone, setTempPhone] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [showPhoneAndAddress, setshowPhoneAndAddress] = useState(false);

  const setButtonStatusString = useCallback(() => {
    switch (status) {
      case "open":
        setConfirmButtonString("Confirm");
        break;
      case "close":
        setConfirmButtonString("Order Closed");
        break;
      case "confirm":
        setConfirmButtonString("Order Confirmed");
        setAddress(tempAddress);
        setPhone(tempPhone);
        setshowPhoneAndAddress(true);
        break;
      case "cancel":
        setConfirmButtonString("Order Cancelled");
        break;
      default:
        setConfirmButtonString("Confirm");
        break;
    }
  }, [status, tempAddress, tempPhone]);

  useEffect(() => {
    let mounted = true;
    const getRequestListWithResponses = async () => {
      const data = await fetchRequestListWithResponses(user, id);
      if (mounted) {
        setRequests(data.requests);
        setResponses(data.responses);
        setBestPriceItemList(data.bestPrice);
        setVendorList(data.vendorList);
        async function getRequestListDetails() {
          if (!user) {
            return;
          }
          const url = REQUEST_LIST_URL + "/" + id;
          const res = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.currentUser.idToken}`,
            },
          });
          if (res.ok) {
            const data = await res.json();
            setStatus(data.status);
            setTempAddress(data.address);
            setTempPhone(data.phone);
          }
        }
        getRequestListDetails();
        setButtonStatusString();
      }
    };
    getRequestListWithResponses();
    return () => (mounted = false);
  }, [id, user, setButtonStatusString]);

  const fetchRequestListWithResponses = async (user, id) => {
    if (!auth.currentUser) {
      return {
        requests: [],
        vendorList: [],
        responses: [],
        bestPrice: {},
      };
    }

    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.currentUser.idToken}`,
      },
    };
    const reqWithResponsesRes = await fetch(
      `${REQUEST_LIST_WITH_RESPONSES_URL}/${id}`,
      options
    );
    const reqListWithResponses = await reqWithResponsesRes.json();
    return {
      requests: reqListWithResponses.requests,
      vendorList: reqListWithResponses.vendors,
      responses: reqListWithResponses.responses,
      bestPrice: reqListWithResponses.bestPrice,
    };
  };

  function showConfirmModal() {
    if (status === "confirm") {
      return;
    }
    setShowModal("modal is-active");
  }

  function closeConfirmModal() {
    setShowModal("modal");
  }

  async function confirmOrder() {
    const url = REQUEST_LIST_URL + "/" + id;
    const patchObject = {
      address: address,
      phone: phone,
      status: "confirm",
    };

    const res = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.currentUser.idToken}`,
      },
      body: JSON.stringify(patchObject),
    });
    if (res.ok) {
      setStatus("confirm");
      setButtonStatusString();
      closeConfirmModal();
    }
  }

  if (!user) {
    return <Redirect to="/my-requests" />;
  }
  return (
    <div>
      <div className={showModal}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Confirm Order?</p>
              <button
                className="delete"
                aria-label="close"
                onClick={closeConfirmModal}
              ></button>
            </header>
            <section className="modal-card-body">
              <label> Address</label>
              <textarea
                className="textarea"
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Address"
              ></textarea>
              <br />
              <label> Phone Number</label>
              <input
                className="input"
                type="tel"
                placeholder="Phone"
                onChange={(e) => setPhone(e.target.value)}
              ></input>
            </section>
            <footer className="modal-card-foot">
              <button className="button is-primary" onClick={confirmOrder}>
                Confirm
              </button>
              <button className="button" onClick={closeConfirmModal}>
                Cancel
              </button>
            </footer>
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={closeConfirmModal}
        ></button>
      </div>
      <div className="columns is-flex is-flex-wrap-wrap mx-6 px-2">
        <div className="card my-request column  m-3">
          <div className="card-header">
            <div className="card-header-icon">
              <BiRupee />/<BiRupee />
            </div>
            <div className="card-header-title">
              <center>Best Price</center>
            </div>
          </div>
          <div className="my-request-list py-1 px-6 is-3">
            <div className=" table-container py-5">
              <table className="table is-narrow is-hoverable is-fullwidth">
                <thead>
                  <tr>
                    <th className="is-align-items-center is-justify-content-center">
                      No.
                    </th>
                    <th>Item</th>
                    <th>
                      <abbr title="Quantity">Qty</abbr>
                    </th>
                    <th>
                      <abbr title="Price per one Item">Price</abbr>
                    </th>
                    <th>
                      <abbr title="Tax Percent">Tax%</abbr>
                    </th>
                    <th>
                      <abbr title="Total price of the Item">Total</abbr>
                    </th>
                    <th>
                      <abbr title="Vendor Name">Vendor</abbr>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bestPriceItemList &&
                    Object.keys(bestPriceItemList).map((requestId, index) => (
                      <MyRequestListWithIdRowComponent
                        key={index}
                        index={index}
                        requestId={requestId}
                        requestList={requests}
                        responses={bestPriceItemList}
                        isBestPrice={true}
                        vendorList={vendorList}
                        setListTotal={setBestPriceListTotal}
                        token={user.currentUser.idToken}
                      />
                    ))}
                  <tr>
                    <td
                      className="py-2 my-3"
                      colSpan="4"
                      style={{ textAlign: "right" }}
                    >
                      <h4 className="pr-2 mr-2"> Total :</h4>
                    </td>
                    <td className="py-2 my-3" style={{ textAlign: "left" }}>
                      <h4 className="pr-2 mr-2">
                        {bestPriceListTotal.toFixed(2) || 0}
                      </h4>
                    </td>
                    <td>
                      <CustomButton type="submit" onClick={showConfirmModal}>
                        {confirmButtonString}
                      </CustomButton>
                    </td>
                  </tr>
                </tbody>
              </table>
              {showPhoneAndAddress ? (
                <div>
                  <p>
                    <b>Phone:</b> {phone}
                  </p>
                  <p>
                    <b>Address:</b> {address}
                  </p>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
        {responses &&
          Object.keys(responses).map((vendorId, index) => (
            <MyRequestListWithIdComponent
              key={index}
              reqListId={id}
              user={user}
              requestList={requests}
              responses={responses}
              vendorId={vendorId}
              vendorList={vendorList}
            />
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = ({ user: { currentUser, loading } }) => ({
  currentUser,
  loading,
});

export default connect(mapStateToProps)(MyRequestListWithIdPage);
