// import { auth } from "../../firebase/firebase.utils";
// import { useState, useEffect } from "react";
// import { API_SERVER_URL } from "../../util/config";
import { BiRupee } from "react-icons/bi";

import MyRequestComponent from "../my-request/my-request.component";

const MyRequestList = ({ user, requestLists }) => {
  return (
    <div className="card my-request column m-1 is-three-fifths">
      <div className="card-header">
        <div className="card-header-icon">
          <BiRupee />/<BiRupee />
        </div>
        <div className="card-header-title">
          <center>{user.currentUser.displayName}</center>
        </div>
      </div>
      <div className="my-request-list py-1 px-6 conatiner is-three-quarters">
        <div className=" table-container py-5">
          <table className="table is-narrow is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th className="is-align-items-center is-justify-content-center">
                  No.
                </th>
                <th>Date</th>
                <th>Catagory</th>
                <th>
                  <abbr title="No. of requests in the list">Num of Items</abbr>
                </th>
                <th>
                  <abbr title="No. of responses from Vendor">Res</abbr>
                </th>
                <th>
                  <abbr title="Current Status of the request">Status</abbr>
                </th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {requestLists.map((request, index) => (
                <MyRequestComponent
                  key={index}
                  index={index}
                  id={request.id}
                  createdAt={request.createdAt}
                  category={request.category}
                  requestCount={request.requestCount}
                  responseCount={request.responseCount}
                  status={request.status}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MyRequestList;
