import { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { BiRupee } from "react-icons/bi";
import { auth } from "../../firebase/firebase.utils";
import { API_SERVER_URL } from "../../util/config";

import VendorRequestItemPriceComponent from "../vendor-request-item-pice/vendor-request-item-price.component";
import CustomButton from "../custom-button/custom-button.component";

const VendorRequestListWithIdComponent = ({ requests, reqId, user }) => {
  const [requestPriceList, setRequestPriceList] = useState([]);
  const [requestItems, setRequestItems] = useState(requests);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    setRequestItems(requests);
  }, [requests]);

  const handlePrice = (event, id, quantity, tax = 18) => {
    const [request] =
      requestPriceList.filter((req) => req.requestId === id) || [];

    if (request) {
      request.priceForOneItem = parseInt(event.target.value);
      request.taxPercent = tax;
      request.price = (
        (parseInt(event.target.value) +
          parseInt(event.target.value) * (tax / 100)) *
        quantity
      ).toFixed(2);
    } else {
      const reqPrice = {
        requestId: id,
        priceForOneItem: parseInt(event.target.value),
        quantity: quantity,
        taxPercent: tax,
        price: (
          (parseInt(event.target.value) +
            parseInt(event.target.value) * (tax / 100)) *
          quantity
        ).toFixed(2),
      };
      setRequestPriceList([...requestPriceList, reqPrice]);
    }
  };

  const handleTax = (event, id, quantity, price) => {
    console.log("tax : ", price, quantity, event.target.value, typeof price);
    const [request] =
      requestPriceList.filter((req) => req.requestId === id) || [];
    if (request) {
      request.taxPercent = parseInt(event.target.value);
      request.price = (
        (price + price * (parseInt(event.target.value) / 100)) *
        quantity
      ).toFixed(2);
    } else {
      const reqPrice = {
        requestId: id,
        priceForOneItem: price,
        taxPercent: parseInt(event.target.value),
        quantity: quantity,
        price: (
          (price + price * (parseInt(event.target.value) / 100)) *
          quantity
        ).toFixed(2),
      };
      setRequestPriceList([...requestPriceList, reqPrice]);
    }
  };
  
  const handleDelete = (id) => {
    console.log(id);
    setRequestItems(requestItems.filter((req) => req.id !== id));
    setRequestPriceList(requestPriceList.filter((req) => req.requestId !== id));
  };

  const handleSubmit = async () => {
    const RESPONSE_LIST_URL = `${API_SERVER_URL}/responses`;

    if (!auth.currentUser) {
      // alert("Please sign-in before you request prices");
      return;
    }

    if (requestPriceList.length === 0) {
      alert(
        "Woah there! Looks like you are in a rush. Remember to fill the list before you submit"
      );
      return;
    }

    if (requestPriceList.length !== requestItems.length) {
      alert(
        "Looks like there few items are empty. Please remove them from the list before you submit"
      );
      return;
    }

    const responseObject = {
      requestlistId: reqId,
      responses: requestPriceList,
    };

    console.log(responseObject);
    const reqOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.currentUser.idToken}`,
      },
      body: JSON.stringify(responseObject),
    };

    fetch(RESPONSE_LIST_URL, reqOptions)
      .then((response) => {
        console.log(response.status);
        setRequestPriceList([]);
        response.status === 200
          ? alert(`You have shot your price. Lets wait for the user.`)
          : alert(`Error code : ${response.status}`);
        setRedirect(true);
      })
      .catch((err) => console.log(err));
  };

  if (user && !user.currentUser.isVendor) {
    return <Redirect to="/vendor/sign-up" />;
  }
  if (redirect || !user) {
    return <Redirect to="/vendor/request-list" />;
  }

  return (
    <div className="card my-request column m-3 is-three-fifths">
      <div className="card-header">
        <div className="card-header-icon">
          <BiRupee />/<BiRupee />
        </div>
        <div className="card-header-title">
          <center>
            {user.currentUser.name} | {reqId}
          </center>
        </div>
      </div>
      <div className="card-content is-clipped px-4">
        <div className="vendor-request-list px-4">
          <center>
            <table className="table is-narrow is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th className="is-align-items-center is-justify-content-center">
                    No.
                  </th>
                  <th>Item Name</th>
                  <th>
                    <abbr title="Quantity">Qty</abbr>
                  </th>
                  <th>
                    <abbr title="Price Per One Item In The List">
                      Price Per Item
                    </abbr>
                  </th>
                  <th>
                    <abbr title="Price Per One Item In The List">
                      Tax Percent
                    </abbr>
                  </th>
                  <th>
                    <abbr title="Total Price of the Item">Total Price</abbr>
                  </th>
                  <th>Delete Item</th>
                </tr>
              </thead>
              <tbody>
                {requestItems &&
                  requestItems.map((request, index) => {
                    const { id, requestString, quantity, taxPercent } = request;
                    return (
                      <VendorRequestItemPriceComponent
                        key={id}
                        id={id}
                        index={index}
                        itemName={requestString}
                        quantity={quantity}
                        taxPercent={taxPercent || 18}
                        handlePrice={handlePrice}
                        handleTax={handleTax}
                        handleDelete={handleDelete}
                      />
                    );
                  })}
              </tbody>
            </table>
          </center>

          <div className="is-8">
            <div className="columns mt-6">
              <span className="column">
                <Link to="/vendor/request-list">
                  <CustomButton isAddItem>Back</CustomButton>
                </Link>
              </span>
              <span className="column">
                <CustomButton onClick={handleSubmit}>Submit</CustomButton>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorRequestListWithIdComponent;
