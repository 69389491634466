import { React, Component } from "react";
import Dropdown from "react-dropdown";
import { withRouter } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { API_SERVER_URL } from "../../../util/config";

const VendorStatus = ["active", "inactive", "pending_verification"];
const VendorStatusMap = {
  active: "is-success",
  inactive: "is-danger",
  pending_verification: "is-warning",
};
const VENDOR_URL = `${API_SERVER_URL}/vendor`;

class AdminVendorComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      name: this.props.name,
      email: this.props.email,
      phoneNumbers: this.props.phoneNumbers,
      address: this.props.address,
      categories: this.props.categories,
      vendorStatus: this.props.vendorStatus,
      color: VendorStatusMap[this.props.vendorStatus],
      user: this.props.user
    };
    this.onStatusChange = this.onStatusChange.bind(this);
  }

  async onStatusChange(event) {
    let color = VendorStatusMap[event.value];
    this.setState({ vendorStatus: event.value });
    this.setState({ color: color });
    const url = VENDOR_URL + "/" + this.state.id;
    const patchObject = {
      vendorStatus: event.value
    };
    const res = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.user.currentUser.idToken}`,
      },
      body: JSON.stringify(patchObject),
    });
    if (res.ok) {
      console.log("status updated to :" + event.value)
    }
    else{
      console.log(await res.json())
    }
  }

  render() {
    return (
      <tr>
        <td className={this.state.color}>{this.state.id}</td>
        <td className={this.state.color}>{this.state.name}</td>
        <td className={this.state.color}>{this.state.email}</td>
        <td className={this.state.color}>{this.state.phoneNumbers[0]},  {this.state.phoneNumbers[1]}</td>
        <td className={this.state.color}>{this.state.address}</td>
        <td className={this.state.color}>{this.state.categories}</td>
        <td className={this.state.color}>
          <Dropdown
            options={VendorStatus}
            value={this.state.vendorStatus}
            onChange={this.onStatusChange}
            placeholder="Select an option"
          />
        </td>
        <td className={this.state.color}>
          {" "}
          <Link to={`/vendor/${this.state.id}`} key={this.state.id}>
            <FaEye className="my-2" />
          </Link>
        </td>
      </tr>
    );
  }
}

export default withRouter(AdminVendorComponent);
