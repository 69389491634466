import { useState, useEffect } from "react";
import { auth } from "../../firebase/firebase.utils";
import { API_SERVER_URL } from "../../util/config";
import { AiOutlineCloseCircle } from "react-icons/ai";

import { CATEGORIES } from "../../categories.data"
import CustomButton from "../../components/custom-button/custom-button.component";
import FormInput from "../../components/form-input/form-input.component";
import ItemsCollection from "../../components/ItemsCollection/itemsCollection.component";
import "./request-list.styles.scss";

const RequestList = (props) => {
  const { user, categoryKey } = props;
  const [requestItemList, setRequestItemList] = useState(props.requestItemList);
  const [requestItem, setRequestItem] = useState("");
  const [category, setCategory] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => { 
    setCategory(CATEGORIES[categoryKey])
  }, [categoryKey]);

  const handleRequestItem = (event) => {
    setRequestItem(event.target.value);
  };

  const handleQuantity = (event) => {
    setQuantity(event.target.value);
  };

  const addRequestItem = (requestItem) => {
    // checks if the category is not empty
    if (!category || category.length === 0) {
      setErrorMessage("Please select a category");
      setRequestItem(requestItem);
      return;
    }
    const id = Math.floor(Math.random() * 1000) + 1;
    const item = {
      id: id,
      itemName: requestItem,
      quantity: parseInt(quantity),
    };
    const newRequestItem = item;
    setRequestItemList([...requestItemList, newRequestItem]);
    console.log("changed", requestItemList);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!requestItem) {
      setErrorMessage("Please enter an item before you proceed");
      return;
    }
    if (!category || category.length === 0) {
      setErrorMessage("Please select a category");
      setRequestItem(requestItem);
      return;
    }
    if (requestItemList.length === 15) {
      setErrorMessage("Only 15 requests can be made in a single requestList");
      return;
    }
    addRequestItem(requestItem);
    setRequestItem("");
    setQuantity(1);
  };
  const sendRequest = async () => {
    const REQUEST_LIST_URL = `${API_SERVER_URL}/requestlist`;

    // check if the user is signed-in
    if (!auth.currentUser) {
      setErrorMessage("Please sign-in before you request prices");
      return;
    }

    // check the user added item to the list
    // prevents submiting empty request list
    if (requestItemList.length === 0) {
      setErrorMessage("Please add an item to request the price");
      return;
    }

    const requestItemListObject = {
      category: category,
      requests: requestItemList,
    };

    const reqOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.currentUser.idToken}`,
      },
      body: JSON.stringify(requestItemListObject),
    };
    fetch(REQUEST_LIST_URL, reqOptions)
      .then((response) => {
        if(response.ok)
        {
          setRequestItemList([]);
          setQuantity(1);
          setSuccessMessage("Your request has been submitted successfully!");
        }
        else
        {
          console.log(response)
          setErrorMessage(response.statusText)
        }
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteRequest = (id) => {
    setRequestItemList(
      requestItemList.filter((requestItem) => requestItem.id !== id)
    );
  };

  const handleEditRequest = (id) => {
    const [editItem] = requestItemList.filter(
      (requestItem) => requestItem.id === id
    );
    setRequestItemList(
      requestItemList.filter((requestItem) => requestItem.id !== id)
    );
    setRequestItem(editItem.itemName);
    setQuantity(editItem.quantity);
  };

  const errorStyles = {
    margin: "30px",
    maxWidth: "700px",
    color: "red",
    border: "2px solid red",
    backgroundColor: "rgba(225, 0, 0, 0.15 )",
    borderRadius: "5px",
  };

  const successStyles = {
    margin: "30px",
    maxWidth: "700px",
    color: "green",
    border: "2px solid green",
    backgroundColor: "rgba(0, 225, 0, 0.15 )",
    borderRadius: "5px",
  };

  return (
    <div className="conatiner pt-6">
      <form onSubmit={handleSubmit} className="container pt-2">
        <div className="columns is-justify-content-space-around is-align-items-center">
          <div className="column is-5">
            <h3> category: { category } </h3>
          </div>
        </div>
        <center>
          {errorMessage.length > 0 && (
            <div style={errorStyles} className="p-2 my-6">
              {errorMessage}
              <div className="icon-container pl-6">
                <AiOutlineCloseCircle
                  className="icon"
                  onClick={() => setErrorMessage("")}
                />
              </div>
            </div>
          )}
        </center>

        <div className="columns is-flex-wrap-wrap is-justify-content-space-around is-align-items-center">
          <div className=" column my-4 is-8">
            <FormInput
              type="text"
              name="requestItem"
              value={requestItem}
              onChange={handleRequestItem}
              label="Request Item"
            ></FormInput>
          </div>
          <div className="column my-4 is-2">
            <FormInput
              type="number"
              name="quantity"
              value={quantity}
              onChange={handleQuantity}
            ></FormInput>
          </div>
          <div className="column my-4 is-2">
            <center>
              <CustomButton isAddItem type="submit">
                Add Item
              </CustomButton>
            </center>
          </div>
        </div>
      </form>
      <ItemsCollection
        requestItemList={requestItemList}
        handleDelete={handleDeleteRequest}
        handleEdit={handleEditRequest}
      />
      <center>
        {successMessage.length > 0 && (
          <div style={successStyles} className="p-2 my-6">
            {successMessage}
            <div className="icon-container pl-6">
              <AiOutlineCloseCircle
                className="icon"
                onClick={() => setSuccessMessage("")}
              />
            </div>
          </div>
        )}
        <CustomButton onClick={sendRequest}>Submit</CustomButton>
      </center>
    </div>
  );
};

export default RequestList;
