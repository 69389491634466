// import { useState, useEffect } from "react";
import VendorRequestItemComponent from "../vendor-request-item/vendor-request-item.component";
//import 'bulma/css/bulma.min.css';

const VendorRequestList = ({ requestList }) => {

  function getCurrentDate(separator='-'){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
    }
    

  return (
    <div>
      <div className="columns">
        <div className="column">
          <p className="is-size-2 has-text-centered has-text-primary">Today's orders</p>
        </div>
        <div className="column">
          <p className="is-size-2 has-text-centered has-text-primary">Date: {getCurrentDate()}</p>
        </div>
        
      </div>
    <div className="vendor-request-list py-1 px-6 conatiner is-three-quarters">
      <div className="columns is-flex-wrap-wrap px-6 mx-1">
        {requestList.map((request, index) => {
          return <VendorRequestItemComponent key={index} request={request} />;
        })}
      </div>
    </div>
    </div>
  );
};

export default VendorRequestList;
