import Item from "../item/item.component";
import "./itemCollection.styles.scss";

const ItemsCollection = ({ requestItemList, handleDelete, handleEdit }) => {
  return (
    <div className="items-collection container">
      {requestItemList.map(({ id, itemName, quantity }) => {
        return (
          <Item
            key={id}
            itemDetails={`${itemName}  X  ${quantity}`}
            id={id}
            onDelete={handleDelete}
            onEdit={handleEdit}
          ></Item>
        );
      })}
    </div>
  );
};

export default ItemsCollection;
