import { UserActionTypes } from "./user.types";

export const setCurrentUser = (user) => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: user,
});

export const setLoading = (boolean) => ({
  type: UserActionTypes.SET_LOADING_USER,
  payload: boolean,
});
