export const REQUESTLISTSTATUSMAP = {
    "open" : "is-primary",
    "confirm": "is-warning",
    "close": "is-dark",
    "cancel": "is-danger"
}

export const REQUESTLISTSTATUS  = [
    "open",
    "confirm",
    "close",
    "cancel"
]