import React from "react";
import { connect } from "react-redux";
import { auth } from "../../firebase/firebase.utils";
import { useEffect, useState } from "react";
import { API_SERVER_URL } from "../../util/config";

import MyRequestList from "../../components/my-request-list/my-request-list.component";
// import "./my-request-list-page.scss";

const REQUEST_LIST_URL = `${API_SERVER_URL}/requestlist`;

const MyRequestListPage = ({ currentUser, loading }) => {
  const [userRequestList, setUserRequestList] = useState([]);
  useEffect(() => {
    const getRequestList = async () => {
      const allUserRequestLists = await fetchRequestList(currentUser);
      setUserRequestList(allUserRequestLists);
    };
    getRequestList();
  }, [currentUser]);

  const fetchRequestList = async (user) => {
    if (!auth.currentUser) {
      //alert("Please sign-in before you check for your requests");
      return [];
    }
    const res = await fetch(REQUEST_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.currentUser.idToken}`,
      },
    });
    const data = await res.json();
    return data;
  };

  return (
    <div>
      <center>
        <h2>My Requests</h2>
        <div className="my-request-list-page px-3">
          {!loading && currentUser && userRequestList.length > 0 ? (
            <div className="my-request-list-page p-2">
              <MyRequestList
                user={currentUser}
                requestLists={userRequestList}
              />
            </div>
          ) : (
            <div>
              <p className="m-3"> No new Requests</p>
            </div>
          )}
        </div>
      </center>
    </div>
  );
};

const mapStateToProps = ({ user: { currentUser, loading } }) => ({
  currentUser,
  loading,
});

export default connect(mapStateToProps)(MyRequestListPage);
